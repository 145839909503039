.readOnlyTableContainer {
  max-width: 400px;
}
.nav-tabs .nav-link[aria-selected='true'] {
  color: #224634;
}
.nav-tabs .nav-link[aria-selected='false'] {
  color: #869b92;
}
.tab-content {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.nav .nav-container {
  -webkit-justify-content: normal;
          justify-content: normal;
}
.nav .nav-items {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
.nav .nav-items .user-section {
  color: #fcfdfe;
}
.nav .nav-items .userName {
  display: inline-block;
  margin: 0 1rem;
}
.nav .toggleIcon {
  margin-left: auto;
}
.nav .amazonConnectLogo {
  margin-left: 1rem;
}
.businessHoursTitle {
  border-bottom: 1px solid #101010;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}
.editIcon {
  cursor: pointer;
  margin-left: 0.5rem;
}
.caret-down {
  cursor: pointer;
  margin-right: 0.5rem;
}
.centered {
  text-align: center;
}
.plus {
  cursor: pointer;
  margin-right: 0.5rem;
}
.editIcon:hover {
  color: #101010;
}
.cursorLoading {
  cursor: progress;
}
.react-bootstrap-table {
  table-layout: auto;
  overflow: auto;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 539px;
  margin-bottom: 10px;
}
.react-bootstrap-table table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: rgb(255, 255, 255);
}
.react-bootstrap-table table tr {
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-bootstrap-table table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.react-bootstrap-table .checkbox {
  margin-left: 30px;
}
.react-bootstrap-table .action-icon {
  margin-left: 4px;
}
.lineOfBusinessTitle {
  font-weight: 700;
  margin: 1rem 0 0.5rem 0;
}
.lineOfBusiness {
  display: inline-block;
  width: 30%;
}
.loadingContainer {
  min-height: 600px;
}
.locations {
  display: inline-block;
  margin-left: 3rem;
  width: 30%;
}
.tableHead {
  text-align: center;
}
input.ant-time-picker-input {
  text-align: center;
}
.notValidHoursAlert {
  color: #FF0000;
  padding: 0 0 2rem 0;
}
.emergencyMessage {
  background-color: #FF0000;
  border-color: #FF0000;
  margin: 1rem 0;
}
.emergencyMessage:hover {
  background-color: #B22222;
  border-color: #B22222;
}
.startEmergencyTime {
  display: inline-block;
}
.radioButtonsDivider {
  margin: auto;
}
.displayContent {
  display: block;
}
.removeContent {
  display: none;
}
.validateForm {
  color: #FF0000;
  margin: 0 0 0.5rem 0;
}
.upcomingEmergencyMessage {
  color: #FFA500;
}
.currentEmergencyMessage {
  color: #FF0000;
}
.emergencyMessageDatePickerradioButtons {
  margin: auto;
}
.emergencyMessageResponses {
  margin: 1rem 0;
}
.emergencyMessageResponseInputText {
  width: 100%;
}
.submitCloseButtons {
  float: right;
  margin: 0.5rem;
}
.emergencyMessageDisplay {
  margin: 0.5rem 0;
}
.emergencyButtonGroup {
  margin: 1rem 0;
}
.emergencyButtonGroup .btn:first-child {
  margin-right: 1rem;
}
.dateTimePicker .rw-btn {
  margin-top: -4px;
}
.formLabel {
  font-weight: 600;
  margin: 0.5rem 0;
}
.formSectionHeader {
  margin-bottom: 0.25rem;
}
.formSection {
  border: 1px solid rgba(16, 16, 16, 0.1);
  border-radius: 0.3rem;
  margin-bottom: 2rem;
  padding-bottom: 5px;
  padding-top: 5px;
}
.formSection.noBorder {
  border: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.formSection:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.formGroup {
  margin-bottom: 0.75rem;
}
.descriptionMessage {
  height: 10rem;
}
.endMessageWarning {
  font-size: 0.8rem;
  font-weight: 400;
}
.textGroup {
  margin-bottom: 1rem;
}
.textGroup .textLabel {
  font-weight: 600;
}
.no-permissions {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.modal-font-size {
  font-size: 0.8rem;
}
.planned-messages .action-icon {
  cursor: pointer;
}
.planned-messages .iconColumn {
  width: 58px;
}
.planned-messages .timeColumn {
  max-width: 100px;
}
.planned-messages .dateColumn {
  max-width: 100px;
}
.planned-messages .categoryColumn {
  max-width: 100px;
}
.planned-messages .descriptionColumn {
  width: 30%;
}
.planned-messages .caret-down {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
}
.planned-messages .categoryColumn,
.planned-messages .descriptionColumn {
  overflow: hidden;
  text-overflow: ellipsis;
}
.planned-messages .add-planned-message-link,
.planned-messages .section-header {
  cursor: pointer;
  display: inline-block;
}
.planned-messages .add-planned-message-icon,
.planned-messages .add-planned-message-link {
  color: #416753;
}
.planned-messages .collapsed .caret-down {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.activeStatus {
  color: #00FF00;
}
.scheduledStatus {
  color: #FFA500;
}
.recurringMessageTable .iconColumn {
  width: 58px;
}
.recurringMessageTable .dateColumn,
.recurringMessageTable .timeColumn {
  width: 100px;
}
.recurringMessageTable .scheduleColumn {
  width: 175px;
}
.recurringMessageTable .descriptionColumn {
  width: auto;
}
.recurringMessageTable .caret-down {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
}
.recurringMessageTable .trash-alt {
  cursor: pointer;
}
.recurringMessageTable .iconPlaceholder {
  display: inline-block;
  height: 1em;
  width: 1em;
}
.recurringMessageTable .collapsed .caret-down {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.rdt.dateTimePicker {
  display: inline-block;
  margin: 0;
  width: 50%;
}
.dateLabel {
  width: 50%;
}
.recurringDate {
  margin-bottom: 1rem;
}
.schedule {
  margin-bottom: 2rem;
}
.schedule .scheduleDays {
  display: inline-block;
  margin: 0.5rem;
}
.durationContainer {
  margin-top: 0.5rem;
}
.durationContainer .durationTime {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}
.titleAndStatusContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.rejectUtterance {
  color: #0000EE;
  text-decoration: underline;
}
.utteranceContainer {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.utteranceContainer .checkCirlce {
  color: #00FF00;
  margin: 0.3rem;
}
.utteranceContainer .denyCircle {
  color: #FF0000;
  margin: 0.3rem;
}
.utteranceContainer .commentCircle {
  margin: 0.3rem;
}
.utteranceContainer .utteranceInput {
  display: inline-block;
  width: 75%;
}
.utteranceContainer .ctrURL {
  color: #101010;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
}
.collapsed .caret-down {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.addUtterance {
  display: inline-block;
  vertical-align: bottom;
  width: 80%;
}
.addUtteranceButton {
  margin-left: 0.5rem;
}
.audioPlayer {
  margin: 0.5rem 0 0.5rem 0;
  width: 95%;
}
.botAliasTitle {
  font-weight: 700;
}
.prodAliasContainer {
  margin-top: 1rem;
}
.textHyperLink {
  color: #0000EE;
  cursor: pointer;
  margin-left: 0.5rem;
  text-decoration: underline;
}
.targetVersionSelect {
  display: inline-block;
  margin-left: 0.5rem;
  width: 20%;
}
.currentAliasVersion {
  margin-bottom: 0.5rem;
}
.botDeployButton {
  margin-top: 1rem;
}
.botAliasDeployMessage {
  font-weight: 700;
}
.botAliasVersion {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.botAliasDeployWarningMessage {
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0.5rem;
}
.adminTabs {
  margin-top: 1.5rem;
}
.adminTitle {
  margin: 1rem 0 1rem 0;
}
.adminKey {
  height: 20rem;
  overflow: scroll;
  width: 50%;
  word-wrap: break-word;
}
.btn-sm {
  padding: 1px 4px;
  font-size: 9pt;
  margin-top: 3px;
}
.userName-col {
  width: 150px;
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
button#pageDropDown {
  margin-top: auto;
}
.dropdown-menu {
  min-width: 4rem;
}
.dropdown-item {
  padding: 0px;
}
/*# sourceMappingURL=index.css.map */

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.agentContainer.container {
  max-width: 1350px !important;
}
.agentContainer .nav-tabs {
  margin-top: 60px;
}

.react-bootstrap-table {
  overflow: auto;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 500px;
}

.react-bootstrap-table.form-control {
  padding: 0px;
  margin: 0px;
  height: 26px;
}

.react-bootstrap-table.formGroup {
  padding: 0px;
  margin: 0px;
}

.react-bootstrap-table table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: rgb(255, 255, 255);
}

.react-bootstrap-table table tr {
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-bootstrap-table .nav.nav-tabs {
  margin-top: 75px;
}
.react-bootstrap-table table tr,
.react-bootstrap-table table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-bootstrap-table.checkbox {
  margin-left: 30px;
}

.react-bootstrap-table.action-icon {
  margin-left: 4px;
}

.agentUpdateNoClick {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #ffffff00;
  cursor: progress;
}

Table.bulkEdit tr {
  font-size: 12pt;
  color: rgb(80, 80, 80);
  line-height: 30px;
}

.agentsTable Button {
  position: relative;
  margin-top: -170px;
  margin-right: 10px;
}

.selectAll {
  width: 10px;
  cursor: pointer;
}

.tableSearch {
  margin-left: 30px;
  margin-top: -10px;
  width: 300px;
  height: 30px;
}

ul {
  list-style-type: none;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-left: 15px;
}

