.agentContainer.container {
  max-width: 1350px !important;
}
.agentContainer .nav-tabs {
  margin-top: 60px;
}

.react-bootstrap-table {
  overflow: auto;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 500px;
}

.react-bootstrap-table.form-control {
  padding: 0px;
  margin: 0px;
  height: 26px;
}

.react-bootstrap-table.formGroup {
  padding: 0px;
  margin: 0px;
}

.react-bootstrap-table table th {
  position: sticky;
  top: 0;
  background: rgb(255, 255, 255);
}

.react-bootstrap-table table tr {
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-bootstrap-table .nav.nav-tabs {
  margin-top: 75px;
}
.react-bootstrap-table table tr,
.react-bootstrap-table table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-bootstrap-table.checkbox {
  margin-left: 30px;
}

.react-bootstrap-table.action-icon {
  margin-left: 4px;
}

.agentUpdateNoClick {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #ffffff00;
  cursor: progress;
}

Table.bulkEdit tr {
  font-size: 12pt;
  color: rgb(80, 80, 80);
  line-height: 30px;
}

.agentsTable Button {
  position: relative;
  margin-top: -170px;
  margin-right: 10px;
}

.selectAll {
  width: 10px;
  cursor: pointer;
}

.tableSearch {
  margin-left: 30px;
  margin-top: -10px;
  width: 300px;
  height: 30px;
}

ul {
  list-style-type: none;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-left: 15px;
}
